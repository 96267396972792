import request from '@/utils/request'
// 提交idfa 和通讯录
function addressBook () {
  if (isUseLatestLogic()) {
    const contant = getContactsFromStorage()
    const idfa = localStorage.getItem('idfa') || ''
    if (contant || idfa) {
      const contantList = JSON.stringify(contant) || JSON.stringify([])
      request.post('addressBook', { contantList, idfa: idfa.replace(/"/g, '') })
    }
  }
}

const LATEST_API_VERSIONS = ['3', '4', '5', '6', '7', '8', '9', '10']
// 判断是否使用最新的逻辑
export function isUseLatestLogic () {
  const apitype = sessionStorage.getItem('apitype')
  return LATEST_API_VERSIONS.includes(apitype)
}

// 兼容多种通讯录储存到localStorage的key
function getContactsFromStorage () {
  // 通讯录储存到localStorage的key：vsdfessdd
  // 姓名：swerf
  // 手机号：rgthh
  const keyList = [
    {
      contactKey: 'contact',
      name: 'accountName',
      phone: 'accountPhone'
    },
    {
      contactKey: 'vsdfessdd',
      name: 'swerf',
      phone: 'rgthh'
    },
    {
      contactKey: 'fdgdfgf',
      name: 'rter',
      phone: 'ertr'
    },
    {
      contactKey: 'oowerd',
      name: 'adsw',
      phone: 'qeer'
    }
  ]
  let contant = ''
  // 使用some方法，当找到有值的key时会自动终止循环
  keyList.some(key => {
    console.log(key.contactKey)
    const valueJSON = localStorage.getItem(key.contactKey)
    if (valueJSON) {
      const valueArray = JSON.parse(valueJSON)
      contant = valueArray.map(item => ({
        accountName: item[key.name],
        accountPhone: item[key.phone]
      }))
      return true
    }
    return false
  })
  return contant
}
export default addressBook
